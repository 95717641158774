import React from 'react'
import ContainerMain from '../../Components/layouts/main/ContainerMain'
import { Container } from 'react-bootstrap'

const TermsAndCondition = () => {
  return (
    <ContainerMain>
    <section className="wrapperMain homeMain termsPage">
      <Container className="z10">
        <h2 className="mainHeading">Terms and Conditions for JINO</h2>
        <div className="policyCntainer">
          <h2>Effective Date: <small>01/01/2025</small></h2>
       <h3>1. General Information</h3>
          <p>JINO provides a one-step car care solution, offering various services to streamline vehicle maintenance and management. These terms apply to all users, including visitors, registered users, and service providers.</p> 
          <h3>2. Account Registration</h3>
          <p>To access certain features, you may need to create an account. You agree to provide accurate and complete information during registration and to update your account details as needed.</p>
          <p>You are responsible for maintaining the confidentiality of your account credentials and for all activities conducted under your account.</p> 
           <h3>3. Use of the Service</h3>  
          <p>You agree to use the Service only for lawful purposes and in accordance with these terms.</p> 
          <p>You must not use the Service to engage in any fraudulent, illegal, or harmful activities.</p> 
          <p>Any unauthorized use of the Service may result in termination of your access.</p> 
          <h3>4. Payment Terms</h3> 
          <p>Payments for services offered through JINO are processed securely via our payment gateway partner, Telr.</p>
          <p> By using the payment feature, you agree to the terms and conditions of Telr.</p>
          <p>All payments are non-refundable unless otherwise specified.</p>
          <h3>5. Cancellation and Refunds</h3> 
          <p> Booking cancellation is allowed until 1 hour before the booking date.</p>
          <p>Cancelled orders can be either requested to be rescheduled or to be refunded following the terms of the cancellation policy.</p>
          <p>Refunds will be done only through the Original Mode of Payment and will be processed within 10 working days depending on the issuing bank of the credit card.</p>
          <h3>6. Intellectual Property</h3> 
          <p>All content, trademarks, logos, and other intellectual property displayed on the Service are owned by JINO or its licensors.</p> 
          <p> You may not reproduce, distribute, or create derivative works without prior written consent from JINO.</p> 
          <h3> 7. Privacy Policy</h3>
          <p> Your use of the Service is subject to our Privacy Policy. Please review it to understand how we collect, use, and protect your information.</p>
          <h3> 8. Limitation of Liability</h3>
          <p>JINO is not liable for any indirect, incidental, or consequential damages arising out of your use of the Service. Our total liability is limited to the amount paid by you for the relevant service.</p> 
          <h3>9. Termination</h3> 
          <p>We reserve the right to suspend or terminate your access to the Service at any time without notice if you breach these terms.</p> 
          <p>Upon termination, your right to use the Service will cease immediately.</p> 
          <h3>10. Changes to Terms</h3> 
          <p>We may update these Terms and Conditions from time to time. Changes will be effective upon posting to the Service.</p> 
          <p> Continued use of the Service after changes constitutes acceptance of the updated terms.</p> 
          <h3> 11. Governing Law</h3>
          <p> These terms are governed by and construed in accordance with the laws of [Your Country/State].</p> 
          <h3>12. Contact Information</h3>
          <p> If you have any questions about these Terms and Conditions, please contact us at:</p> 
          <h3>JINO Support Team</h3>
          <p> Email: [support@example.com]</p> 
          <p> Phone: [Your Contact Number]</p> 
        </div>
      </Container>
      <div className="text-center">
        <img src="/images/circle.png" alt="" className="circlebg" />
      </div>
    </section>
    </ContainerMain>
  )
}

export default TermsAndCondition