import React, { useCallback, useState, useRef, useContext, memo } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Services from "./Pages/Services/Services";
import Header from "./Components/Header/Header";
import PostLoginServices from "./Pages/post-login/PostLoginServices";
// import Checkout from "./Pages/checkout/Checkout";
import Checkout from "./Pages/checkout/Checkout";
import Thanks from "./Pages/thanks-message/Thanks";
import BookinHistory from "./Pages/booking-history/BookinHistory";
import Profile from "./Pages/profile/Profile";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { loadUser } from "./features/auth.slice";
import Policy from "./Pages/policy/Policy";
import AboutUs from "./Pages/About/AboutUs";
import Download from "./Pages/download/Download";
import { get } from "./services/api.service";
import { setUser } from "./features/user.slice";
import spinnerSvc from "./services/spinner-service";
import LoadingIndicator from "./Components/loader/loader";
import CompanyProfile from "./Pages/company-profile";
import ServicesMain from "./Pages/services-main";
import TermsAndCondition from "./Pages/terms/TermsAndCondition";

function App() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    checkUser();
    const subscription = spinnerSvc.requestInProgress.subscribe((isLoading) =>
      setIsLoading(isLoading)
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const checkUser = async () => {
    const user = localStorage.getItem("Token");
    if (user) {
      const userDate = await get("customer");
      dispatch(loadUser(userDate.result));
      dispatch(setUser(userDate.result));
    }
  };

  const ProtectedRoute = memo(({ children }: { children: React.ReactNode }) => {
    const userToken = localStorage.getItem("Token");
    if (userToken) {
      return <>{children}</>;
    }

    return <Navigate to="/" />;
  });
  return (
    <>
      <div className="wrapper">
        {isLoading && <LoadingIndicator />}
        <Router>
          <Routes>
            <Route path="/" element={<PostLoginServices />} />
            <Route
              path="/checkout/:status"
              element={
                // <ProtectedRoute>
                <Checkout />
                // </ProtectedRoute>
              }
            />
            <Route
              path="/checkout"
              element={
                // <ProtectedRoute>
                <Checkout />
                // </ProtectedRoute>
              }
            />
            <Route path="/download" element={<Download />} />

            <Route
              path="/thanks"
              element={
                // <ProtectedRoute>
                <Thanks />
                // </ProtectedRoute>
              }
            />
            <Route
              path="/booking-history"
              element={
                // <ProtectedRoute>
                <BookinHistory />
                // </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                // <ProtectedRoute>
                <Profile />
                // </ProtectedRoute>
              }
            />
            <Route
              path="/for-business"
              element={
                // <ProtectedRoute>
                <CompanyProfile />
                // </ProtectedRoute>
              }
            />
            <Route
              path="/editlocation"
              element={
                // <ProtectedRoute>
                <Services />
                // </ProtectedRoute>
              }
            />
            <Route path="/privacy-policy" element={<Policy />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/services" element={<ServicesMain />} />
            
            <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
